<template>
  <b-row v-if="reasonProcessing.length > 0">
    <b-col cols="12">
      <b-card class="my-card m-0">
        <b-table
          striped
          small
          id="myTables"
          bordered
          selectable
          selected-variant="primary"
          select-mode="single"
          responsive="sm"
          :items="reasonProcessing"
          :fields="fields"
          @row-dblclicked="editForm"
          @row-clicked="clicked"
          head-row-variant="secondary"
        >
          <template #cell(checkbox)="row">
            <b-form-checkbox
              v-model="row.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="row.toggleDetails"
            >
            </b-form-checkbox>
          </template>
          <template #cell(is_active)="data">
            <span v-if="data.item.is_active == true"> Активен </span>
            <span v-else> Не активен </span>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
  props: ["reasonProcessing"],
  data() {
    return {
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "20px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "25px" } },
        {
          key: "name",
          label: "Причина",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "status",
          label: "Статус причини обработки",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "is_active",
          label: "Стутус",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "created_user.full_name",
          label: "Кто создал",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "created_at",
          label: "Дата создания",
          sortable: true,
          thStyle: { width: "200px" },
        },
      ],
    };
  },
  mounted() {
    resizeable();
  },
  methods: {
    editForm(id) {
      this.$emit("editForm", id);
    },
    clicked(item, index, event) {
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.textContent.trim();
      cellSelect();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
